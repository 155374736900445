
.cate-template {
    height: 100%;
    color: #333;
    .content {
        font-size: 16px;
        margin-bottom: 15px;
        .input-item {
            margin-bottom: 12px;
            text-align: start;
            .label {
                text-align: start;
                margin-bottom: 12px;
            }
            ::v-deep .el-cascader {
                width: 100%;
            }
        }
    }
    .delete {
        margin: 50px 0;
    }
}
